
module.exports = {
    "hero-head":"तुम्ही महाराष्ट्रातील सर्वोत्तम सेक्सोलॉजिस्ट शोधत आहात का?",
    "welcome": "डॉ. व्यास सुपर स्पेशलिटी सेक्सोलॉजी क्लिनिक मध्ये आपले स्वागत आहे",
    "Book An Appointment": "अपॉइंटमेंट बुक करा",
    "appointment-para":"तुमच्या लैंगिक आरोग्याच्या चिंतांना डॉक्टरांना सुरक्षित आणि खाजगीरित्या विचारा. रुग्णांनी आम्हाला दिलेली सर्व माहिती फक्त डॉक्टरांनाच उपलब्ध आहे.",
    "confidential":"सम्पूर्ण गोपनीय",
    "welcome-subhead":"एम.एस. (सेक्सोलॉजिस्ट), पी.जी. डिप्लोमा (सेक्सोलॉजी), डॉ. (सेक्सोलॉजी), पी.जी.डी.पी.जी. एंड सी. (मानसातील), एम-एसेक्ट (सेक्सोलॉजी), एम-सीएसईपी (अंतरराष्ट्रीय), एम-आयएपीपी (मानसशास्त्र), डबल (पीजीडी), डीएससी (सेक्सोलॉजी) ",
   "description":"डॉ. प्रभु व्यास आणि उच्च पात्र लैंगिक तज्ञांची टीम हे भारतातील सर्वात विश्वासू डॉक्टर आहेत. आमच्या क्लिनिकमध्ये, आम्ही समजतो की लैंगिक आरोग्याच्या समस्या शारीरिक आणि भावनिक दोन्ही असू शकतात आणि उपचारासाठी आमचा दृष्टीकोन सर्वांगीण आणि रुग्ण आहे- केंद्रीत आम्ही तुमच्या समस्या ऐकण्यासाठी आणि तुमच्या विशिष्ट गरजा आणि उद्दिष्टे पूर्ण करणारी वैयक्तिक उपचार योजना विकसित करण्यासाठी वेळ काढतो.", 
    "experience": "सेक्सोलॉजीमध्ये 35+ वर्षांचा अनुभव",
    "guaranteed_results": " खात्रीशीर परिणाम",
  "results_in_7_days": "7 दिवसांच्या आत खात्रीशीर परिणाम",
  "best_results": "सर्वोत्तम परिणाम मिळवा",
  "best_treatment": "सर्वोत्तम उपचार मिळवा",
  "effective_for_men": "पुरुषांच्या लैंगिक समस्यांवर प्रभावी परिणाम",
  "no_side_effects": "कोणतेही दुष्परिणाम नसलेले उपचार",
  "reliable_doctor": "भारतातील विश्वसनीय सेक्सोलॉजिस्ट डॉक्टर",
  "media":"आमच्या डॉक्टरचे मीडिया कव्हरेज",
  "certificate":"येथे आमची काही प्रमाणपत्रे आहेत",
  "treatment-head":"ज्या आजारांवर आपण उपचार करतो",
  "treatment-para":"जवळजवळ प्रत्येक लैंगिक समस्येवर उपचार केले जाऊ शकतात. म्हणून, आताच स्वतःचे निदान करा आणि आमच्या क्लिनिकमध्ये उपचार करून तुमचे नाते वाचवा.",
  "treatment-name1":"नपुंसकता",
    "treatment-name2":"इरेक्टाइल डिसफंक्शन",
    "treatment-name3":"शीघ्रपतन",
    "treatment-name4":"लैंगिक इच्छा कमी होणे",
    "treatment-name5":"स्पर्मेटोरिया (धातू जाणे)",
    "treatment-name6":"यौन कमजोरी",
    "treatment-name7":"कमी शुक्राणूंची संख्या",
    "treatment-name8":"नाईट फॉल किंवा वेट ड्रीम्स",
    "treatment-name9":"लिंग-रोपण",
    "video":"YouTube वर आमचे सेक्स एज्युकेशन व्हिडिओ पहा",
    "branch":"आमच्या शाखा"
    

  }
  