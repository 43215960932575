module.exports = {
    "hero-head":"क्या आप महाराष्ट्र में सर्वश्रेष्ठ सेक्सोलॉजिस्ट की खोज कर रहे हैं?",
    "welcome": "डॉ. व्यास सुपर स्पेशलिटी सेक्सोलॉजी क्लिनिक में आपका स्वागत है",
    "Book An Appointment": "अपॉइंटमेंट बुक करें",
    "appointment-para":" अपने यौन स्वास्थ्य संबंधी चिंताओं को डॉक्टरों से सुरक्षित और निजी रूप से पूछें। मरीजों द्वारा हमें दी गई सभी जानकारी केवल डॉक्टरों के लिए सुलभ है।",
    "confidential":" पूरी तरह गोपनीय ",
    "welcome-subhead":"एम.एस. (सेक्सोलॉजिस्ट), पी.जी. डिप्लोमा (सेक्सोलॉजी), डॉ. (सेक्सोलॉजी), पी.जी.डी.पी.जी. एंड सी. (मानसिक), एम-एसेक्ट (सेक्सोलॉजी), एम-सीएसईपी (अंतरराष्ट्रीय), एम-आईएएपीपी (मनोविज्ञान), डबल (पीजीडी), डीएससी (सेक्सोलॉजी)",
   "description":" डॉ. प्रभु व्यास और उच्च योग्य सेक्सोलॉजिस्ट की टीम भारत में सबसे भरोसेमंद डॉक्टर हैं। हमारे क्लिनिक में, हम समझते हैं कि यौन स्वास्थ्य समस्याएं शारीरिक और भावनात्मक दोनों हो सकती हैं, और उपचार के लिए हमारा दृष्टिकोण समग्र और धैर्यपूर्ण है- केंद्रित। हम आपकी चिंताओं को सुनने और एक वैयक्तिकृत उपचार योजना विकसित करने के लिए समय निकालते हैं जो आपकी विशिष्ट आवश्यकताओं और लक्ष्यों को संबोधित करती है।",
   "experience": "सेक्सोलॉजी में 35+ वर्षों का अनुभव",
    "guaranteed_results": " गारंटीकृत परिणाम",
    "results_in_7_days": "7 दिनों के भीतर गारंटीकृत परिणाम",
    "best_results": "सर्वश्रेष्ठ परिणाम प्राप्त करें",
    "best_treatment": "सर्वश्रेष्ठ उपचार प्राप्त करें",
    "effective_for_men": "पुरुषों की यौन समस्याओं पर प्रभावी परिणाम",
    "no_side_effects": "कोई दुष्प्रभाव नहीं होने वाले उपचार",
    "reliable_doctor": "भारत के विश्वसनीय सेक्सोलॉजिस्ट डॉक्टर",
    "media":"हमारे डॉक्टर की मीडिया कवरेज",
    "certificate":"यहां हमारे कुछ प्रमाणपत्र हैं",
    "treatment-head":"जिन बीमारियों का हम इलाज करते हैं",
    "treatment-para":"लगभग हर यौन समस्या का इलाज संभव है। तो, अभी अपना निदान करवाएं और हमारे क्लिनिक में इलाज करवाकर अपने रिश्ते को बचाएं।",
    "treatment-name1":"नपुंसकता",
    "treatment-name2":"कम या कोई इरेक्शन नहीं",
    "treatment-name3":"जल्दी डिस्चार्ज (शीघ्रपतन)",
    "treatment-name4":"यौन इच्छा में कमी",
    "treatment-name5":"धातु रोग",
    "treatment-name6":"यौन कमजोरी",
    "treatment-name7":"बांझ*पन या कम शुक्राणु संख्या",
    "treatment-name8":"रात्रि पतन या स्वप्नदोष",
    "treatment-name9":"लिंग-रोपण",
    "video":"Watch Our Sex Education Videos on YouTube",
    "branch":"हमारी शाखाएँ"
  }
  